import angular from "angular";
import ascommon from "../../ascommon";
import {LoginModel} from "./login-models";

angular.module('LoginModule').controller('adminLoginController', ['$scope', 'loginUtils', async function($scope, loginUtils){
    $scope.loginLoading = false;
    $scope.autoFocus = false;
    $scope.token = "";

    const urlParams = new URLSearchParams(window.location.search);
    $scope.errors = [...urlParams.getAll('ErrorMessages')];

    $scope.data = {
        username: "asobgyn",
        returnUrl: loginUtils.getReturnUrl()
    } as LoginModel;

    $scope.token = urlParams.get('adminToken');
    if ($scope.token) {
        $scope.data.password = $scope.token;
    }

    window.addEventListener('load', () => {
        loginUtils.setFingerprint($scope, $scope.data);
        $scope.autoFocus = !$scope.autoFocus;

        if ($scope.token) {
            $scope.adminSignIn();
        }
    });

    $scope.adminSignIn = async function () {
        $scope.loginLoading = true;

        $scope.errors = [];

        let data = $scope.data;
        data.clientTimezoneOffset = new Date().getTimezoneOffset();

        ascommon.post<LoginModel>(
            "/login",
            data,
            (result) => {
                let urlParams = new URLSearchParams();
                urlParams.set("fingerprint", data.fingerprint);
                urlParams.set("returnUrl", data.returnUrl);
                urlParams.set("clientTimezoneOffset", data.clientTimezoneOffset.toString());

                return window.location.href = "/LoginNotifications?" + urlParams.toString();
            },
            (error) => {
                $scope.data.password = "";
                $scope.errors = error.response.data.ErrorMessages;
                $scope.$apply();

                $scope.autoFocus = !$scope.autoFocus;
            },
            (result) => {
                $scope.loginLoading = false;
                $scope.$apply();
            }
        );
    };
}]);
