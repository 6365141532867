import angular from 'angular'; // need this otherwise build errors ensue
import $ from "jquery";
import axios from "axios";

let instance: AxiosInstance;

export const getHttpClient = async (): Promise<AxiosInstance> => {
    if(!instance) {
        instance = axios.create();
        instance.defaults.baseURL = '/api';

        const token = self.sessionStorage ? self.sessionStorage.getItem('authorization_token') : null;

        if (!token) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/AuthTokenMvc/GetToken', false);
            xhr.send();
            xhr.onload = function() {
                let response = xhr.response;
                if (self.sessionStorage) {
                    self.sessionStorage.setItem('authorization_token', response.token);
                }
                instance.defaults.headers.common['Authorization'] = response.token ? `Bearer ${response.token}` : '';
            };
        }
        else {
            instance.defaults.headers.common['Authorization'] = token;
        }
    }

    return instance;
}

export const setAuthToken = async (token: string) => {
    const client = await getHttpClient();

    if (self.sessionStorage) {
        self.sessionStorage.setItem('authorization_token', token);
    }
    client.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';

    ascommon.log('HttpClient: configured auth token');
}
